@use "../../styles/globals/layout";
@use "../../styles/globals/variables";

.filter-input {
  &:disabled {
    background-color: #243740;
    font-weight: bold;
    color: #282c34;
    &:hover {
      cursor: not-allowed;
    }
  }
}

label {
  padding-bottom: 5px;
}
.filters {
  /*line-height: 2em;*/
  display: flex;
  flex-direction: column;
}


.back-button-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 5px;
  .back-button {
    all: unset;
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: 20px;
    height: 30px;
    width: 30px;
    font-weight: bold;
    margin: 5px;
    border-radius: 5px;
    text-align: center;
    justify-content: center;
    &:hover  {
      background-color: #4b566c;
    }
    &:focus {
      outline: orange 5px auto;

    }
  }
  .back-button-title {
    font-size: 20px;
    font-weight: bold;
  }
}

.results-container {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 0;
  margin: 0;
}

.collapse-side-panel {
  all: unset;
  background-color: rgba(255, 255, 255, 0.32);
  color: white;
  width: .1rem;
  height: 3rem;
  padding: .5em;
  display: flex;
  justify-content: center;
  align-items: center;
  //place-content: center;
  //border: 1px solid variables.$border-color-light;
  border-radius: 5px 0 0 5px;
  position: absolute;
  right: 0;
  margin-top: .3em;
  //opacity: 0;
  //display: none;
  transition: opacity .1s ease-out;
  //&:hover {
  //  opacity: .6;
  //}
  //top: 0;
}
.closed {
  transition: opacity .1s ease-out;
  opacity: .2;
  &:hover {
    opacity: .7;
  }
}
.open {
  transition: opacity .1s ease-out;
  opacity: .2;
  &:hover {
    opacity: .7;
  }
}
.warning-button {
  background-color: variables.$background-warning;
  opacity: .7;
  &:hover {
    opacity: .9;
  }
}
.error-button {
  background-color: variables.$background-error;
  opacity: .7;
  &:hover {
    opacity: .9;
  }
}
.suggested {
  color: variables.$text-color;
  font-size: 24px;
  text-align: start;
  margin-left: 10px;
  margin-bottom: 5px;
}