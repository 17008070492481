$glass: rgba(255, 255, 255, 0.2);
$glass-icon: rgba(255, 255, 255, 0.3);
$option: #320a28;
.modal-select, .schedule-select {
  /* Reset */
  appearance: none;
  border: 0;
  outline: 0;
  font: inherit;
  /* Personalize */
  width: 18em;
  height: 3em;
  padding: 0 4em 0 1em;
  background: url(https://upload.wikimedia.org/wikipedia/commons/9/9d/Caret_down_font_awesome_whitevariation.svg)
  no-repeat right 0.8em center / 1.4em,
  linear-gradient(to left, $glass-icon 3em, $glass 3em);
  color: white;
  border-radius: 0.25em;
  box-shadow: 0 0 1em 0 rgba(0, 0, 0, 0.2);
  cursor: pointer;
  /* <option> colors */
  option {
    color: inherit;
    background-color: $option;
  }
  /* Remove focus outline */
  &:focus {
    outline: none;
  }
  /* Remove IE arrow */
  &::-ms-expand {
    display: none;
  }
}

.ReactModal__Content {
  top: '50%';
  left: '50%';
  width: '30%';
  color: 'white';
  display: 'flex';
  align-items: 'center';
  justify-content: 'center';
  margin-right: '-50%';
  transform: 'translate(-50%; -50%)';
  border: '1px solid #ccc';
  background: '#415561';
  overflow: 'auto';
  border-radius: '10px';
  outline: 'none';
  padding: '20px';
}

.modal-container {
  font-weight: bold;
  display: flex;
  align-items: center;
  flex-direction: column;
  row-gap: 20px;
  color: white;

  .modal-row {
    width: 100%;
    display: flex;
    justify-content: center; 
  }
 
  .modal-title {
    font-size: 20px;
  }
  .start-button {
    color: inherit;
    font-weight: inherit;
    background-color: #688692;
    border-style: unset;
    padding: 1em;
    border-radius: 5px;
    font-size: 15px;
    transition: all .5s ease;

    &:hover {
      filter: brightness(1.1);
      transform: translateY(-1px);
    }
  }
}