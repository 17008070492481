@use "../../../styles/globals/variables";
.results {
    display: flex;
    flex-direction: column;
    row-gap: 5px;
    background-color: variables.$background-main;
    padding: 5px;
    overflow-y: scroll;
    /*height: 100%;*/
    /*height: 100%;*/
    /*column-gap: 10px;*/

    /*column-gap: 10px;*/
}