.auth-options-container {
    display: flex;
    align-items: center;
    justify-content: center;

    .auth-button {
        cursor: pointer;
        border: 0;
        border-radius: 4px;
        font-weight: 600px;
        margin: 0 10px;
        width: 100px;
        padding: 10px 0;
        box-shadow: 0 0 20px rgba(129, 122, 175, 0.2);
        transition: 0.4s;

        &:hover {
            color: white;
            box-shadow: 0 0 20px rgb(103,135,255,0.6);
            background-color: rgb(103,135,255);
        }
    }
    .signup {
        color: rgb(103,135,255);
        background-color: rgba(255, 255, 255, 1);
        border: 1px solid rgb(103,135,255)
    }

    .login {
        color: white;
        background-color: rgb(103,135,255);
    }
}
.search-icon {
    height: 40px;

    &:hover {
        cursor: pointer;
    }
}