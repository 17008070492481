/*@import "./FormElementStyles/Select.scss";*/
@use "globals/variables";

.App {
  text-align: center;
}
html {
  height: 100vh;
}
body, #app, #app>div, .main-app-body {
  font-family: Arial, serif;
  height: 100%;
  background-color: variables.$background-main;
}
.main-app-body {
  display: flex;
  flex-direction: column;
}
.modal {
  background-color: #282c34;
}
