@use "../../styles/globals/variables" as v;

.navbar {
    height: 70px;
    background-color: darken(v.$background-main, 2);
    border-bottom: 1px solid v.$border-color-light;
    //box-shadow: black 0 5px 15px;
    //box-shadow: rgba(23, 23, 24, 0.314) 0px 10px 20px -10px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .right-side-container {
        padding-right: 15px;
    }
    .left-side-container {
        align-items: center;
        margin-left: 10px;
        font-size: 20px;
        display: flex;
        column-gap: 25px;
        //flex-direction: row;
        .logo {
            font-size: 25px;
            font-weight: bold;
            &:hover {
                cursor: pointer;
            }
        }
        .nav-bar-schedule-name {
            font-size: 16px;
            //font-weight: bold;
        }
    }
}