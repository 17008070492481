@use "../../styles/globals/variables";
.schedule-options {
  padding: 10px;
  display: flex;
  flex-direction: row;
  column-gap: 10px;
  flex-wrap: wrap;
  row-gap: 10px;
  justify-content: center;
  /*overflow-wrap: ;*/
  /*flex-direction: column;*/
}
.vertical-tabs {
  display: flex;
  flex-direction: row;
  height: inherit;
  //overflow-y: scroll;
}
.tabs {
  display: flex;
  flex-direction: column;
}
.tab {

}
.tab-content {
  display: flex;
  height: inherit;
  justify-content: center;
  box-sizing: border-box;
  overflow-y: scroll;
}

.block {
  position: relative; // to position the new buttons
  /*display: inline-block;*/
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  column-gap: 10px;
  /*row-gap: 10px;*/
  width: 300px;
  height: 200px;
  /*height: 250px;*/
  /*margin: 8px;*/
  padding: 8px;
  text-align: center;
  background-color: variables.$background-light;
  border-radius: 10px;
  border: 1px solid rgba(143, 143, 158, 0.32);
  //box-shadow: 0 5px 5px rgba(0, 0, 0, 0.41);
  //color: white;
  transition: all .5s ease;
  &:hover {
    cursor:pointer;
    filter: brightness(1.2);
    transform: translateY(-3px);

  }
  img {
    /*display: block;*/
    /*width: 100%;*/
    /*height: calc(3 / 5 * 100%);*/
    object-fit: cover;
    object-position: center;
    margin-bottom: 8px;
  }


  .delete-button,
  .copy-button {
    position: absolute;
    top: 8px;
    right: 8px;
    //width: 24px;
    //height: 24px;
    cursor: pointer;
    color: white;
    //background-image: url("delete-image.jpg"); // replace with actual image path
    background-size: cover;
    background-position: center;
    background-color: transparent;
    border: none;
    opacity: .6;
    border-radius: 5px;
    &:hover {
      background-color: rgba(255, 255, 255, 0.3);

    }
  }

  .copy-button {
    right: 40px; // position the copy button to the right of the delete button
    //background-image: url("copy-image.jpg"); // replace with actual image path
  }


}
  /*.block div {*/
  /*  !*height: calc(2 / 5 * 100%);*!*/
  /*  display: flex;*/
  /*  flex-direction: column;*/
  /*  justify-content: center;*/
  /*  align-items: center;*/
  /*  !*font-size: 16px;*!*/
  /*}*/
  
.new {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #254058;
  color: #fff;
  font-size: 64px;
  font-weight: bold;
  cursor: pointer;
}
  
  .block.new:hover {
    filter: brightness(1.2);
  }

.schedule-filters-containers {
  display: flex;
  align-items: center;
  justify-content: center;
}
.schedule-filters {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  column-gap: 20px;
  row-gap: 10px;
}
.filter {
  /*display: flex;*/
  /*flex-grow: 0;*/
  /*align-items: center;*/
  width: 25%;
}
.schedule-select-container {
  font-size: 16px;
  color: white;
  /*background-color: #344850;*/
  /*height: 100%;*/
}
.schedule-name {
  font-size: 24px !important;
  font-weight: bold;
  padding-bottom: 10px;
}

.profile-flex-container {
  top: 50%;
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  //height: 100%;
}
.profile-layout {
  //margin: auto;
  box-shadow: 0 0 30px -5px variables.$background-dark;
  border: 1px solid variables.$border-color-light;
  border-radius: 10px;
  width: 60vw;
  height: 70vh;
  background-color: variables.$background-light;
}
.log-out {
  //margin: 10px;
}
.form-item {
  padding-bottom: 10px;
}
.academic-form {
  display: flex;
  flex-direction: column;
}